var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

$(function () {

    var $win = $(window);
    var breakpoint = 768;

    if (window.innerWidth <= breakpoint) {
        var headerHeight = 50;
    } else {
        headerHeight = 83;
    }

    loading();
    headerFixed();

    function loading () {
        var loading = $('.loading');

        var isHidden = function isHidden () {
            loading.fadeOut(1000);
            $('.body').css(
                { opacity: '0' }
            ).animate(
                { opacity: '1' }, 1000
            );
        };
        setTimeout(isHidden, 1000);
    }

    function headerFixed() {
        var $hero = $('.hero');
        var $header = $('.header');
        var $nav = $('.nav');
        var heroPosition = $hero.outerHeight();
        if (window.innerWidth >= breakpoint) {
            $(window).scroll(() => {
                if ($(window).scrollTop() > heroPosition) {
                    $header.addClass('js-fixed');
                    $nav.addClass('js-fixed');
                } else if($(window).scrollTop() > headerHeight && $(window).scrollTop() < heroPosition) {
                    $header.addClass('js-fixed-02');
                    $header.removeClass('js-fixed');
                } else {
                    $header.removeClass('js-fixed');
                    $header.removeClass('js-fixed-02');
                    $nav.removeClass('js-fixed');
                }
            });
        }

    }

    // ----------------------------------------------------
    // navカレント表示
    // ----------------------------------------------------

    var box = new Array;
    var current = -1;
    $('.section').each(function(i) {
        box[i] = $(this).offset().top;
    });

    // カレント表示の変更
    function changeBox(secNum) {
        if (secNum != current) {
            current = secNum;
            secNum2 = secNum + 1;
            $('.nav li').removeClass('js-current');
            $('.nav li:nth-child(' + secNum2 +')').addClass('js-current');
        }
    };

    // scroll量での判定
    function scrollCheck() {

        scrollTop = $(window).scrollTop() + 150;
        height = $(document).height();
        scrollPos = $(window).height() + $(window).scrollTop();
        heroPosition = $('.hero').outerHeight();
        sec2 = $('#service').offset().top;

        for (var i = box.length - 1 ; i >= 0; i--) {
            if ((height - scrollPos) / height < 0.05) {
                $('.nav').find('li:last-child').addClass('js-current').siblings().removeClass('js-current');
            } else if (scrollTop > box[i]) {
                if ( $('.nav').find('li:last-child').hasClass('js-current') ){
                    $('.nav').find('li:last-child').prev().addClass('js-current').siblings().removeClass('js-current');
                }
                changeBox(i);
                break;
            } else if (scrollTop > heroPosition && scrollTop <= sec2) {
                $('.nav').find('li:first-child').addClass('js-current');
            } else if (scrollTop < heroPosition || scrollTop >= sec2) {
                $('.nav').find('li:first-child').removeClass('js-current');
            }
        };

    }

    // scrollでのカレント表示をbind
    $(window).bind('load scroll', scrollCheck);


});
